import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Post from '../components/post'
import Navigation from '../components/navigation'

import style from '../styles/post.module.css'


import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

const TravelsPage = ({data}) => {
  const {html, frontmatter} = data.markdownRemark
  const photos = frontmatter.photos.map((photo) => (
    photo.childImageSharp
  ))
  return (
    <>
      <SEO />
      <Layout>
        <div className={style.post}>
          <div className={style.postContent}>
            <h1>{ frontmatter.title }</h1>
            <div dangerouslySetInnerHTML={{ __html: html }} />

            <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/23993041&color=%237c674b&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            <div style={{
              fontSize: '10px',
              color: '#cccccc',
              lineBreak: 'anywhere',
              wordBreak: 'normal',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
              fontWeight: '100',
            }}>
              <a href="https://soundcloud.com/recordette-records" title="Recordette Records" target="_blank" style={{
                color: '#cccccc',
                textDecoration: 'none'
              }}
              >Recordette Records</a> ·
              <a href="https://soundcloud.com/recordette-records/sohail-rana-the-khyber-twist" title="Sohail Rana-The Khyber Twist" target="_blank" style={{
                color: '#cccccc',
                textDecoration: 'none'
              }}
              >Sohail Rana-The Khyber Twist</a>
            </div>
            <Gallery images={photos} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export const imageQuery = graphql`
  query {
    markdownRemark(frontmatter: {path: {eq: "/travels"}}) {
      html
      frontmatter {
        title
        photos{
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid_withWebp
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default TravelsPage